<template>
  <div class="page">
    <header>
      <h1 class="title">Novo conteúdo</h1>
      <p class="subtitle">Define um título e insira o conteúdo que será visualizado pelos seus alunos</p>
    </header>
    <div class="body">
      <div class="form">
        <div class="e-group">
          <label for="title">Nome: <span class="required">*</span> </label>
          <input v-model="form.title" id="title" type="text" class="einput">
        </div>

        <div class="e-group">
          <label for="#title">Descrição: </label>
          <textarea v-model="form.description" class="einput" style="height: 150px !important;"></textarea>
        </div>

        <div class="e-group">
          <label for="#title">Conteúdo: <span class="required">*</span></label>

          <input @change="isYoutubeVideo(form.videoUrl)" v-model="form.videoUrl" id="title" type="text" class="einput"
            :class="{ error: form.errorUrl }">

          <p class="desc" :class="{ error: form.errorUrl }">
            <svg fill="none" viewBox="0 0 20 20" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.8469 8.22969L9.09688 5.72969C9.00273 5.66687 8.89329 5.6308 8.78024 5.62533C8.6672 5.61985 8.55479 5.64517 8.45501 5.69859C8.35523 5.75201 8.27183 5.83152 8.21371 5.92863C8.15559 6.02575 8.12493 6.13682 8.125 6.25V11.25C8.12493 11.3632 8.15559 11.4743 8.21371 11.5714C8.27183 11.6685 8.35523 11.748 8.45501 11.8014C8.55479 11.8548 8.6672 11.8801 8.78024 11.8747C8.89329 11.8692 9.00273 11.8331 9.09688 11.7703L12.8469 9.27031C12.9326 9.21326 13.0029 9.13591 13.0515 9.04513C13.1002 8.95436 13.1256 8.85298 13.1256 8.75C13.1256 8.64702 13.1002 8.54564 13.0515 8.45487C13.0029 8.36409 12.9326 8.28674 12.8469 8.22969ZM9.375 10.082V7.42188L11.3734 8.75L9.375 10.082ZM16.875 3.125H3.125C2.79348 3.125 2.47554 3.2567 2.24112 3.49112C2.0067 3.72554 1.875 4.04348 1.875 4.375V13.125C1.875 13.4565 2.0067 13.7745 2.24112 14.0089C2.47554 14.2433 2.79348 14.375 3.125 14.375H16.875C17.2065 14.375 17.5245 14.2433 17.7589 14.0089C17.9933 13.7745 18.125 13.4565 18.125 13.125V4.375C18.125 4.04348 17.9933 3.72554 17.7589 3.49112C17.5245 3.2567 17.2065 3.125 16.875 3.125ZM16.875 13.125H3.125V4.375H16.875V13.125ZM18.125 16.25C18.125 16.4158 18.0592 16.5747 17.9419 16.6919C17.8247 16.8092 17.6658 16.875 17.5 16.875H2.5C2.33424 16.875 2.17527 16.8092 2.05806 16.6919C1.94085 16.5747 1.875 16.4158 1.875 16.25C1.875 16.0842 1.94085 15.9253 2.05806 15.8081C2.17527 15.6908 2.33424 15.625 2.5 15.625H17.5C17.6658 15.625 17.8247 15.6908 17.9419 15.8081C18.0592 15.9253 18.125 16.0842 18.125 16.25Z"
                fill="currentColor"></path>
            </svg>
            <span>
              {{ !form.errorUrl ? 'Informe neste campo apenas a URL do vídeo no youtube.' : 'Este link está inválido' }}
            </span>

          </p>
        </div>
        <div class="e-group">
          <label for="idioma">Idioma: <span class="required">*</span></label>
          <select id="idioma" class="einput">
            <option value="pt">Português</option>
          </select>
          <p class="desc">Informe o idioma desta aula.</p>
        </div>
        <div class="btns">
          <button @click="createLesson()" class="btn active"
            :disabled="form.title == '' || form.videoUrl == '' || form.errorUrl">Criar
            conteúdo</button>
          <button @click="cancel" class="btn cancel">Cancelar</button>
        </div>
      </div>
    </div>
    <ScreemLoading :show="loading" />
  </div>
</template>
  
<script>
import { api } from "@/api";
import { mapState } from "vuex";
import ScreemLoading from "../../../components/theme/ScreemLoading.vue";

export default {
  name: "CreateCourse",
  computed: {
    ...mapState(["courses"])
  },
  data() {
    return {
      loading: false,
      form: {
        title: "",
        description: "",
        videoUrl: "",
        idioma: "pt"
      },
      errors: {
        file: {
          show: false,
          message: ""
        }
      }
    }
  },
  created() {
    this.form = {
      title: "",
      description: "",
      errorUrl: false,
      videoUrl: "",
      idioma: ""
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async createLesson() {
      if (this.form.title != '' && this.form.videoUrl != '') {
        try {
          this.loading = true
          const res = await api.post(`courses/lesson/${this.$route.params.course_id}/${this.$route.params.module_id}`, this.form)
          if (res.status == 201) {
            this.$router.go(-1)
            this.$store.dispatch("actionSetToast", { text: "Conteúdo criado com sucesso!", role: "success" })
          }
        } catch (err) {
          this.$router.go(-1)
          this.$store.dispatch("actionSetToast", { text: "Conteúdo criado com sucesso!", role: "success" })
          console.log(err.message)
        } finally {
          this.loading = false
        }
      } else return
    },
    isYoutubeVideo(url) {
      var v = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return (url.match(v)) ? this.form.errorUrl = false : this.form.errorUrl = true;
    }
  },
  components: {
    ScreemLoading
  }
};
</script>
  
<style scoped>
.page {
  color: #333;
}

header {
  margin-bottom: 24px;
}

h1 {
  font-size: 32px;
  font-weight: 500;
  color: #333;
  padding-right: 30px
}

.subtitle {
  opacity: .75;
  font-weight: 400;
}

/*Body style */
.fast-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.body {
  width: 50%;
}

.e-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  padding: 4px 0;
}

.required {
  color: #f14668;
}

.error {
  color: #f14668 !important;
  border-color: #f14668 !important;
}

.desc {
  display: flex;
  font-weight: 400;
  font-size: 85%;
  opacity: 0.8;
  margin-top: 3px;
}

.desc svg {
  margin-right: 4px;
  opacity: .3;
}

.btns button {
  margin-right: .75rem;
}

@media(max-width: 767px) {
  .body {
    width: 100%;
  }
}
</style>